<!-- 学生成长 -->
<template>
  <div class="studentGrowth">
    <div class="top-img">
      <img src="static/lvya/banner/03.png" alt="">
    </div>
    <div class="module module-01">
      <div class="w1200">
        <div class="tip-text">
          <p class="title">构建面向未来的育人体系</p>
          <p class="line"></p>
          <p class="text w1090">围绕学校整体育人目标，自上而下进行学生成长的顶层设计，以灵活的低 代码平台和强大的大数据分析系统为支撑，以兼顾学生对学科知识的掌握以及通 用能力培养的方式，为学校构建可落地执行的学生成长体系</p>
        </div>
        <img class="img wow animate__fadeInUp" src="static/lvya/schoolFzpt/studentGrowth/01.png" alt="">
      </div>
    </div>
    <div class="module module-02">
      <div class="w1200">
        <div class="tip-text">
          <p class="title">产品亮点</p>
          <p class="line"></p>
        </div>
        <div class="tip2-text">
          <div class="title">
            <img src="static/lvya/schoolFzpt/studentGrowth/dian.png" alt="">
            <p>以学生能力培养为目标的教育模型</p>
          </div>
          <p class="text2 w1060">从学校使命愿景出发，确定学生的能力模型，构建与学生能力模型相适应的多元化课程体系，以课程创新引领教学实践变革，让学生在课程任务中形成全面而有个性的能力素养。</p>
        </div>
        <img class="img2 wow animate__fadeInUp" src="static/lvya/schoolFzpt/studentGrowth/02.png" alt="">
        <div class="tip2-text">
          <div class="title">
            <img src="static/lvya/schoolFzpt/studentGrowth/dian.png" alt="">
            <p>学生多元评价体系</p>
          </div>
          <p class="text2 w1060">基于育人场景、课程和学业成绩，融合表现性评价、过程性评价与纸笔测验，实现对学生多元评价，从多角度深刻描绘学生能力画像。</p>
          <img class="img2 wow animate__fadeInUp" src="static/lvya/schoolFzpt/studentGrowth/03.png" alt="">
        </div>
        <div class="tip2-text">
          <div class="title">
            <img src="static/lvya/schoolFzpt/studentGrowth/dian.png" alt="">
            <p>学生能力画像</p>
          </div>
          <p class="text2 w1060">在过去，我们用纸笔考试来考察学生对于基础理论知识的学习掌握，以分数作为衡量和评价学生能力的唯一标准。在未来，我们将关注学生应用知识解决实际问题的能力，引导学生培养综合素质和创新思维。</p>
          <img class="img2 wow animate__fadeInUp" src="static/lvya/schoolFzpt/studentGrowth/04.png" alt="">
        </div>
        <div class="tip2-text">
          <div class="title">
            <img src="static/lvya/schoolFzpt/studentGrowth/dian.png" alt="">
            <p>立体成绩单</p>
          </div>
          <p class="text2 w1060">放眼未来，立足当下。在满足学生能力培养的同时，对学生的学科成绩依然给予足够的重视。</p>
          <img class="img2 wow animate__fadeInUp" src="static/lvya/schoolFzpt/studentGrowth/05.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
          boxClass: 'wow',
          animateClass: 'animate__animated',
          offset: '0',
          mobile: true,
          live: true
      })
      wow.init()
    })
  }
}
</script>

<style lang="less" scoped>
.studentGrowth{
  width: 100%;
  min-width: 1200px;
  .top-img{
    line-height: 0;
    img{
      width: 100%;
      min-width: 1200px;
    }
  }
  .module{
    width: 100%;
    min-height: 200px;
    .w1200{
      width: 1200px;
      min-height: 100px;
      margin: 0 auto;
      padding: 50px 0 20px 0;
      box-sizing: border-box;
      .tip-text{
        text-align: center;
        .title{
          color: #222222;
          font-size: 30px;
          font-weight: bold;
        }
        .line{
          width: 63px;
          height: 5px;
          background: #19CA81;
          margin: 15px auto;
        }
        .text{
          font-size: 16px;
          color: #696969;
          line-height: 28px;
        }
        .w1090{
          width: 1090px;
          margin: 0 auto;
        }
      }
      .tip2-text{
        margin-top: 15px;
        .title{
          width: 1060px;
          margin: 0 auto;
          display: flex;
          align-content: center;
          img{
            width: 21px;
            height: 21px;
            margin-right: 10px;
            margin-top: 2px;
          }
          p{
            font-size: 18px;
            color: #222222;
            font-weight: bold;
          }
        }
        .text2{
          font-size: 16px;
          color: #696969;
          margin-top: 15px;
          line-height: 28px;
        }
        .w1060{
          width: 1060px;
          margin: 0 auto;
          margin-top: 15px;
        }
      }
      .tip2-text:first-child{
        margin-top: 60px;
      }
      .img{
        width: 100%;
        margin-top: 60px;
      }
      .img2{
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  .module-01{
    min-height: 700px;
    background: url('/static/lvya/schoolFzpt/studentGrowth/bg.png') no-repeat;
    background-size: 100% 100%
  }
}
</style>
